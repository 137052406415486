// --------------------------------------------------
// REACT
// --------------------------------------------------
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// --------------------------------------------------
// HELPERS
// --------------------------------------------------
import { isBrowser } from 'mobile-device-detect';
import { smartypants } from '../../helpers/helpers';

// --------------------------------------------------
// COMPONENTS
// --------------------------------------------------
import { Link } from 'gatsby';
import Img from 'gatsby-image';

// --------------------------------------------------
// STYLES
// --------------------------------------------------
import styles from './post-feed-item.module.scss';


const PostFeedItem = ( { post, linkRel } ) => {
	// useState https://blog.logrocket.com/a-guide-to-usestate-in-react-ecb9952e406c/
	const [ showImage, setShowImage ] = useState( false );

	let { title, category, date, thumbnail } = post;
	title = title || post.fields.slug;

	return (
		<>
			<Link className={styles.link} to={post.fields.slug} rel={linkRel} onMouseOver={ () => { setShowImage( true ); } } onMouseOut={ () => { setShowImage( false ); } } onFocus={ () => { setShowImage( true ); } } onBlur={ () => { setShowImage( false ); } }>
				<article className={styles.article}>
					<h1 className={styles.title}>{smartypants( title )}</h1>
					<small className={styles.details}>
						<dl className={styles.defnList}>
							<div className={styles.defnListItem}>
								<dt>Project type</dt>
								<dd>{category}</dd>
							</div>
							<div className={styles.defnListItem}>
								<dt>Date</dt>
								<dd>{date}</dd>
							</div>
						</dl>
					</small>
				</article>
			</Link>

			{/* Project thumb on hover only for browsers, not mobile devices */}
			{isBrowser && thumbnail ? (
				showImage ? (
					<div className={styles.imageWrap}>
						{!!thumbnail.index.file && !!thumbnail.index.file.childImageSharp ? (
							<Img
								fluid={{
									...thumbnail.index.file.childImageSharp.fluid,
									sizes: `${thumbnail.index.file.childImageSharp.fluid.aspectRatio * 100}vh`,
								}}
								itemProp="image"
								className={styles.image}
							/>
						) : (
							<img src={thumbnail.index.file.publicURL} className={styles.image} itemProp="image"/>
						) }
					</div>
				) : null
			) : null}
		</>
	);
};

PostFeedItem.defaultProps = {
	linkRel: null,
};

PostFeedItem.propTypes = {
	post: PropTypes.object.isRequired,
	linkRel: PropTypes.string,
};

export default PostFeedItem;
